import { useState, useEffect, useCallback, useRef } from 'react';
import {
    Box,
    Chip,
    IconButton,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    useTheme
} from '@mui/material';
import { FilterList, Upload } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import ListAnalysis from './ListAnalysis';
import { getAllCourses } from '../../../API/Course';
import { useReactToPrint } from 'react-to-print';
import { getAllUsers } from '../../../API/getUsers';

const Content = ({ users }) => {
    const theme = useTheme();
    const handleDelete = () => {
        console.info('You clicked the delete icon.');
    };
    const [User, setUser] = useState('student');
    const [courses, setCourses] = useState([]);
    const [fake, setFake] = useState(false);
    const [data, setData] = useState([]);
    const handleChange = (event) => {
        setUser(event.target.value);
        const requiredUser = data.filter(
            (obj) => obj.role === event.target.value
        );
        // console.log('Required Users', requiredUser);
        setData(requiredUser);
    };

    const formatDuration = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const formattedDuration = `${hours}h ${minutes}m`;
        return formattedDuration;
    };

    const courseColumn = [
        {
            field: 'courseName',
            headerName: 'Name',
            flex: 1
        },
        {
            field: 'createdAt',
            headerName: 'Created On',
            flex: 1,
            valueFormatter: (params) => {
                // Assuming updatedAt is a valid date string
                const date = new Date(params.value);
                return date.toLocaleString(); // Adjust the formatting as needed
            }
        },
        {
            field: 'duration',
            headerName: 'Course Duration',
            flex: 1,
            valueFormatter: (params) => {
                const durationInSeconds = params.value;
                const formattedDuration = formatDuration(durationInSeconds);
                return formattedDuration;
            }
        },
        {
            field: 'subCategory',
            headerName: 'Sub Category',
            flex: 1
        },
        {
            field: 'majorCategory',
            headerName: 'Major Category',
            flex: 1
        }
    ];

    const columns = [
        // {
        //     field: '_id',
        //     headerName: 'Id.',
        //     renderCell: (params) => {
        //         params.api.getRowIndex(params.row.id) + 1;
        //     },
        //     flex: 1.5
        // },
        {
            field: '_id',
            headerName: 'Id.',
            renderCell: (params) => {
                return params.api.getRowIndex(params.row._id) + 1;
            },
            flex: 1
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1
        },
        {
            field: 'userName',
            headerName: 'User Name',
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            flex: 1
        },

        {
            field: 'email',
            headerName: 'Email',
            align: 'center',
            type: 'number',
            headerAlign: 'center',
            flex: 1.5
        },
        {
            field: 'majorCategory',
            headerName: 'Major Category',
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            flex: 1
        },
        {
            field: 'subCategory',
            headerName: 'Sub Category',
            flex: 1,
            headerAlign: 'center',
            align: 'center'
        }
    ];

    const initialValues = useCallback(
        (passedData) => {
            const currentUsers = passedData.filter((obj) => obj.role === User);
            setData(currentUsers);
        },
        [User]
    );

    // useEffect(
    //     () =>
    //         users
    //         ? getAllUsers().then((response) => initialValues(response))
    //         : getAllCourses().then((response) => setCourses(response))
    //     [initialValues, User, users]
    // );
    useEffect(() => {
        if (users) {
            getAllUsers()
                .then((response) => initialValues(response))
                .catch((error) => {
                    console.error('Error fetching users:', error);
                    // Handle the error if needed
                });
            // setFake(!fake);
        } else {
            getAllCourses()
                .then((response) => setCourses(response))
                .catch((error) => {
                    console.error('Error fetching courses:', error);
                    // Handle the error if needed
                });
        }
    }, [initialValues, User, users]);

    const tableRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => tableRef.current,
        documentTitle: 'List'
    });

    const getPageMargins = () => {
        return `@page {
            size: Tabloid landscape;
            margin: 50px 0px 50px 0px !important;
        }`;
    };

    return (
        <Box px={{ xs: 4.5, md: 9 }}>
            <Box display="flex" justifyContent="space-between">
                <Chip
                    label="All Categories"
                    variant="contained"
                    onDelete={handleDelete}
                />
                <Box display="flex">
                    {/* <IconButton color="secondary" sx={{ mr: 4 }}>
                        <FilterList sx={{ mr: 1 }} />
                        Filter
                    </IconButton> */}
                    {users && (
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                                User Type
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                value={User}
                                sx={{ width: 139 }}
                                label="User Type"
                                onChange={handleChange}
                            >
                                <MenuItem value="student">Student</MenuItem>
                                <MenuItem value="instructor">
                                    Instructor
                                </MenuItem>
                                {/* <MenuItem value="labAssistant">
                                    Lab Assistant
                                </MenuItem> */}
                            </Select>
                        </FormControl>
                    )}
                </Box>
            </Box>
            <Box mt={4}>
                <IconButton
                    color="secondary"
                    sx={{ mr: 4 }}
                    onClick={handlePrint}
                >
                    <Upload sx={{ mr: 1 }} />
                    EXPORT
                </IconButton>
                <Box height={400} width="100%" mt={1} ref={tableRef}>
                    <style>{getPageMargins()}</style>
                    <DataGrid
                        rows={users ? data : courses}
                        columns={users ? columns : courseColumn}
                        getRowId={(rows) => rows?._id}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        sx={{
                            [theme.breakpoints.down('sm')]: {
                                display: 'none'
                            }
                        }}
                    />
                    <ListAnalysis data={data} users={users && users} />
                </Box>
            </Box>
        </Box>
    );
};

Content.propTypes = {
    users: PropTypes.bool.isRequired
};

export default Content;
