import { useEffect, useState } from 'react';
import { getPoll } from '../../../API/Course';
import { Box, Grid, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import BreadCrumb from '../../../common/components/Breadcrumb';
import { pollsBreadcrumb } from '../../../utils/StaticData/Breadcrumbs/Course';
import { useNavigate } from 'react-router-dom';

export const Polls = () => {
    const navigate = useNavigate();

    const [polls, setPolls] = useState([]);

    useEffect(() => {
        getPolls();
    }, []);

    const getPolls = async () => {
        const res = await getPoll();
        // console.log('res of get poll', res);
        setPolls(res);
    };

    return (
        <>
            <Box px={{ md: 14, sm: 3, xs: 3 }}>
                <Box my={3}>
                    <BreadCrumb breadItems={pollsBreadcrumb} />
                </Box>
                <Grid
                    container
                    display="flex"
                    alignItems="center"
                    // justifyContent="space-between"
                    gap={5}
                >
                    {polls?.map((poll, index) => {
                        return (
                                <Grid
                                    item
                                    key={index}
                                    sx={{
                                        padding: 3,
                                        borderRadius: 2,
                                        boxShadow: 2,
                                        cursor: 'pointer'
                                    }}
                                    md={3}
                                    sm={5.5}
                                    xs={12}
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/poll-details/${poll?._id}`
                                        )
                                    }
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold !important'
                                        }}
                                    >
                                        {poll?.question}
                                    </Typography>
                                </Grid>
                        );
                    })}
                </Grid>
            </Box>
        </>
    );
};
