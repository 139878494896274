import { useEffect, useState } from 'react';
import { getPoll, getVotesOfPoll } from '../../../API/Course';
import { Box, Grid, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import BreadCrumb from '../../../common/components/Breadcrumb';
import { pollsBreadcrumb } from '../../../utils/StaticData/Breadcrumbs/Course';
import { useParams } from 'react-router-dom';

export const PollDetails = () => {

    const { id } = useParams();
    const [poll, setPoll] = useState([]);
    const [question, setQuestion] = useState("");

    const getVotes = async () => {
        const res = await getVotesOfPoll(id);
        // console.log("res of getvotes of poll", res);
        setPoll(res);
        setQuestion(res.votes[0].question);
    }

    useEffect(() => {
        getVotes();
    }, []);

    return (
        <>
            <Box px={{ md: 14, sm: 3, xs: 3 }}>
                <Box my={3}>
                    <BreadCrumb breadItems={pollsBreadcrumb} />
                </Box>
                <Grid
                    container
                    display="flex"
                    alignItems="center"
                    // justifyContent="space-between"
                    gap={5}
                >

                    <Grid
                        item
                        sx={{
                            padding: 3,
                            borderRadius: 2,
                            boxShadow: 2
                        }}
                        md={3}
                        sm={5.5}
                        xs={12}
                    >
                        <Box mb={2}>
                            <Typography
                                sx={{
                                    fontWeight: 'bold !important'
                                }}
                            >
                                {question}
                            </Typography>
                        </Box>
                        {poll?.data?.map((item, i) => {
                            return (
                                <>
                                    <Box
                                        key={i}
                                        sx={{
                                            display: 'flex',
                                            justifyContent:
                                                'flex-start',
                                            width: '100%',
                                            flexDirection: 'column',
                                            my: 2
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent:
                                                    'space-between',
                                                width: '100%',
                                                flexDirection: 'row',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Box mb={1}>
                                                <Typography variant="body1">
                                                {Object.values(item)[0]}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'flex-start',
                                                    flexDirection:
                                                        'row',
                                                    alignItems:
                                                        'center',
                                                    gap: 2
                                                }}
                                            >
                                                <Typography>
                                                    {item.percentage} %
                                                </Typography>
                                                <MoreHorizIcon />
                                            </Box>
                                        </Box>
                                        <Box sx={{ width: '100%' }}>
                                            <LinearProgress
                                                variant="determinate"
                                                value={item.percentage}
                                                sx={{
                                                    '& .MuiLinearProgress-bar':
                                                    {
                                                        borderRadius: 2
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </>
                            )
                        })}


                    </Grid>

                </Grid>
            </Box>
        </>
    );
};
