import * as React from 'react';
import Axios from 'axios';
import { useEffect, useState } from 'react';
import {
    Box,
    Stack,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import BreadCrumb from '../../../common/components/Breadcrumb/index';
import Apiconfig from '../../../config/ApiConfig';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const breadCrumb = [
    {
        id: '01',
        breadItem: 'categories'
    }
];

const validationSchema = Yup.object({
    categoryName: Yup.string().required('Category Name is required'),
    categoryCode: Yup.string()
        .max(8, 'Category Code should be at most 8 characters')
        .required('Category Code is required')
});

const columns = [
    { field: 'categoryCode', headerName: 'Category Code', flex: 2 },
    {
        field: 'categoryName',
        headerName: 'Category Name',
        flex: 2,
        editable: true
    },
    {
        field: 'updatedAt',
        headerName: 'Created On',
        flex: 1,
        type: 'date',
        editable: true,
        valueFormatter: (params) => {
            // Assuming updatedAt is a valid date string
            const date = new Date(params.value);
            return date.toLocaleString(); // Adjust the formatting as needed
        }
        // align: 'center'
    }
    // {
    //     field: 'numberOfUser',
    //     headerName: 'No of users',
    //     type: 'number',
    //     width: 140,
    //     editable: true,
    //     align: 'center'
    // },
    // {
    //     field: 'Subcategories',
    //     headerName: 'Subcategories',
    //     type: 'number',
    //     width: 160,
    //     editable: true,
    //     align: 'center'
    // }
];

const AdminCategory = () => {
    const token = localStorage.getItem('token');
    const [category, setCategory] = useState({
        categoryName: '',
        categoryCode: ''
    });
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const handleCategory = (event) => {
        setCategory({ ...category, [event.target.name]: event.target.value });
    };
    const [data, setData] = useState([]);
    useEffect(() => {
        Axios.get(Apiconfig.category.getAllCategories)
            .then((res) => setData(res.data.data))
            .catch((err) => console.log(err));
    }, [open]);
    const navigate = useNavigate();
    // console.log('user data', data);

    const formik = useFormik({
        initialValues: {
            categoryName: '',
            categoryCode: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            // Your form submission logic here
            const response = await Axios.post(
                Apiconfig.category.createCategory,
                values,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            // console.log('response', response);
            if (response.data.success) {
                toast.success('Category Created successfully');
            }
            setOpen(false);
            formik.setFieldValue('categoryName', '');
            formik.setFieldValue('categoryCode', '');
        }
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        formik.setFieldValue('categoryName', '');
        formik.setFieldValue('categoryCode', '');
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };
    return (
        <>
            <Box sx={{ mb: 1.5, mx: { xs: 4, md: 9 } }}>
                {' '}
                <BreadCrumb breadItems={breadCrumb} />
            </Box>

            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-end"
                marginLeft={-500}
                marginRight={10}
            >
                <Box sx={{ mb: '10px' }}>
                    <Button
                        variant="contained"
                        onClick={handleClickOpen}
                        color="secondary"
                    >
                        ADD category
                    </Button>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Add new category</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Enter category name and category code here.
                                Category code can be 8 characters long only.
                            </DialogContentText>
                            <form onSubmit={formik.handleSubmit}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="categoryName"
                                    name="categoryName"
                                    label="Enter category name here"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    // onChange={formik.handleChange}
                                    onChange={(e) => {
                                        const trimmedValue =
                                            e.target.value.replace(/^\s+/, ''); // Trim spaces before any character
                                        formik.handleChange(e);
                                        formik.setFieldValue(
                                            'categoryName',
                                            trimmedValue
                                        );
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.categoryName}
                                    error={
                                        formik.touched.categoryName &&
                                        Boolean(formik.errors.categoryName)
                                    }
                                    helperText={
                                        formik.touched.categoryName &&
                                        formik.errors.categoryName
                                    }
                                />
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="categoryCode"
                                    name="categoryCode"
                                    label="Enter category code"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    // onChange={formik.handleChange}
                                    onChange={(e) => {
                                        const trimmedValue =
                                            e.target.value.replace(/^\s+/, ''); // Trim spaces before any character
                                        formik.handleChange(e);
                                        formik.setFieldValue(
                                            'categoryCode',
                                            trimmedValue
                                        );
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.categoryCode}
                                    error={
                                        formik.touched.categoryCode &&
                                        Boolean(formik.errors.categoryCode)
                                    }
                                    helperText={
                                        formik.touched.categoryCode &&
                                        formik.errors.categoryCode
                                    }
                                />

                                <DialogActions>
                                    <Button onClick={handleClose}>
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        disabled={
                                            !formik.isValid ||
                                            formik.isSubmitting
                                        }
                                    >
                                        Confirm
                                    </Button>
                                </DialogActions>
                            </form>
                        </DialogContent>
                    </Dialog>
                </Box>
                <Box sx={{ mb: '10px' }}>
                    <Button
                        variant="outlined"
                        onClick={handleClickOpen2}
                        color="secondary"
                        startIcon={<DeleteIcon />}
                    >
                        Delete
                    </Button>
                    <Dialog open={open2} onClose={handleClose2}>
                        <DialogTitle>Delete categories</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                All the contents inside this category will be
                                deleted. Are you sure you want to delete this
                                category?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose2}>Cancel</Button>
                            <Button onClick={handleClose2}>Confirm</Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </Stack>

            <Box sx={{ height: 500, width: '90%', ml: '60px' }}>
                <DataGrid
                    rows={data}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    // checkboxSelection
                    getRowId={(row) => row?._id}
                    id={Math.random()}
                    disableSelectionOnClick
                    onRowClick={(details) => {
                        // console.log('row details', details);
                        navigate('/dashboard/category/subcategory', {
                            state: {
                                data: 'data',
                                subCategories: details.row.subCategories,
                                majorId: details.id
                            }
                        });
                    }}
                />
            </Box>
        </>
    );
};

export default AdminCategory;
