import Axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jwt_decode from 'jwt-decode';
import authActions from './constants';
import ApiConfig from '../../../config/ApiConfig';
import { store } from '../../../store';

export function startLogin() {
    return {
        type: authActions.LOGIN_START
    };
}
export function LoginSuccess(user) {
    // console.log('success login user', user);
    return {
        type: authActions.LOGIN_SUCCESS,

        user
    };
}
export function LoginFailed(errorMessage) {
    return {
        type: authActions.LOGIN_FAILURE,
        error: errorMessage
    };
}

export const login = (
    values,
    setLoading,
    navigate,
    toast,
    handleLoginError
) => {
    // console.log('calling api');
    // console.log('values', values);
    return async () => {
        try {
            store.dispatch(startLogin());
            const response = await Axios.post(ApiConfig.auth.login, values);
            setLoading(false);
            // console.log('login success', jwt_decode(response.data.data.token));

            if (!response.data.success) {
                toast.error(response.data.error);
                handleLoginError(); // Call the new error handler
            }
            // console.log('decoded user', user);
            if(jwt_decode(response.data.data.token)?.role === "admin") {
                localStorage.setItem('token', response.data.data.token);
                const user =
                    response.data.data.token &&
                    jwt_decode(response.data.data.token);
                store.dispatch(LoginSuccess(user));
                navigate('/dashboard');
            } else {
                toast.error("Invalid admin credentials");
            }
        } catch (error) {
            console.log('error', error);
            setLoading(false);
            toast.error(error.response.data.errors[0].msg);
            handleLoginError(); // Call the new error handler
            // toast.error(error.response.data.message);
        }

        // console.log(response);
        // toast.error(response.data.message);
        // store.dispatch(LoginFailed(response.data.message));

        //   localStorage.setItem("token", response.data.data.token);
        <ToastContainer />;
    };
};
