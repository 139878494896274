import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Chip,
    Grid,
    Stack,
    Typography
} from '@mui/material';
import DataSaverOffIcon from '@mui/icons-material/DataSaverOff';
import { useNavigate } from 'react-router-dom';
import GroupIcon from '@mui/icons-material/Group';

const Data = [
    {
        id: 1,
        category: 'Cloud Computing',
        title: 'Cloud Developer using Microsoft Azure',
        lessons: 12,
        videos: 34,
        labs: 23
    },
    {
        id: 2,
        category: 'Web Develeopment',
        title: 'Front End Developer using React and typescript',
        lessons: 6,
        videos: 9,
        labs: 1,
        progress: 80
    },
    {
        id: 3,
        category: 'Web Development',
        title: 'Backend Developer using NodeJs and MongoDb',
        lessons: 32,
        videos: 9,
        labs: 5,
        progress: 55
    },
    {
        id: 4,
        category: 'Cyber Security',
        title: 'Attack Specialist using white hat tools',
        lessons: 9,
        videos: 28,
        labs: 7,
        progress: 90
    },
    {
        id: 5,
        category: 'Cyber Security',
        title: 'Attack Specialist using Red hat tools',
        lessons: 9,
        videos: 28,
        labs: 7,
        progress: 90
    },
    {
        id: 6,
        category: 'Cyber Security',
        title: 'Attack Specialist using bare minimum skills',
        lessons: 9,
        videos: 28,
        labs: 7,
        progress: 90
    },
    {
        id: 7,
        category: 'Cyber Security',
        title: 'Threat analysis using any framework of your choice',
        lessons: 9,
        videos: 28,
        labs: 7,
        progress: 90
    }
];

const SubCatCourse = () => {
    const Navigate = useNavigate();
    return (
        <Grid container spacing={{ xs: 5, md: 6, xl: 12 }} sx={{ mt: 0 }} p={4}>
            {Data.map((item, index) => (
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    width="288px"
                    height="306px"
                    mb={12}
                    columnSpacing={2}
                    key={index}
                >
                    <Card>
                        <CardMedia
                            component="img"
                            height="140"
                            image="https://images.indianexpress.com/2020/09/ronaldo-portugal.jpg"
                            alt="green iguana"
                        />
                        <CardContent>
                            <Chip label="cloud computing" />
                            <Typography variant="body2" color="#09111C" mb={1}>
                                {item.title}
                            </Typography>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                mb={2}
                            >
                                <Typography
                                    weight="400"
                                    size="13px"
                                    lineHeight="15.6px"
                                    letter="0.4px"
                                    variant="subtitle2"
                                >
                                    lesson 23
                                </Typography>
                                <Typography
                                    weight="400"
                                    size="13px"
                                    lineHeight="15.6px"
                                    letter="0.4px"
                                    variant="subtitle2"
                                >
                                    excerise 10
                                </Typography>
                                <Typography
                                    weight="400"
                                    size="13px"
                                    lineHeight="15.6px"
                                    letter="0.4px"
                                    variant="subtitle2"
                                >
                                    labs 10
                                </Typography>
                            </Stack>
                            <Stack
                                direction="row"
                                justifyContent="space-around"
                            >
                                <Stack>
                                    <Stack direction="row">
                                        <DataSaverOffIcon color="secondary" />
                                        <Typography
                                            variant="subtitle2"
                                            color="#264E7D"
                                        >
                                            Track Progress
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row">
                                        <GroupIcon color="secondary" />
                                        <Typography
                                            variant="subtitle2"
                                            color="#264E7D"
                                        >
                                            Group Chat
                                        </Typography>
                                    </Stack>
                                </Stack>

                                <Button
                                    size="small"
                                    color="secondary"
                                    onClick={() => {
                                        Navigate(
                                            '/dashboard/view-course/:courseId'
                                        );
                                    }}
                                >
                                    view
                                </Button>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default SubCatCourse;
