import { useEffect, useState } from 'react';
import { getSurvey } from '../../../API/Course';
import { Box, Grid, Typography } from '@mui/material';
import BreadCrumb from '../../../common/components/Breadcrumb';
import { surveyBreadcrumb } from '../../../utils/StaticData/Breadcrumbs/Course';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import GroupIcon from '@mui/icons-material/Group';
import { useNavigate } from 'react-router-dom';

export const Survey = () => {
    const [data, setData] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        getSurveys();
    }, []);

    const getSurveys = async () => {
        const res = await getSurvey();
        // console.log('res of getSurvey', res);
        setData(res);
    };

    return (
        <>
            <Box px={{ md: 14, sm: 3, xs: 3 }}>
                <Box my={3}>
                    <BreadCrumb breadItems={surveyBreadcrumb} />
                </Box>
                <Grid
                    container
                    display="flex"
                    alignItems="center"
                    // justifyContent='space-between'
                    gap={5}
                >
                    {data?.map((item, index) => {
                        return (
                                <Grid
                                    item
                                    sx={{
                                        padding: 3,
                                        borderRadius: 2,
                                        boxShadow: 2,
                                        cursor: 'pointer'
                                    }}
                                    key={index}
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/survey-details/${item?._id}`
                                        )
                                    }
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: '25px !important',
                                            mb: 2,
                                            color: 'text.primary'
                                        }}
                                    >
                                        {item?.title.charAt(0).toUpperCase() +
                                            item?.title.slice(1)}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '15px !important',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                            color: 'text.primary'
                                        }}
                                    >
                                        <QuestionAnswerIcon
                                            sx={{ color: 'primary.main' }}
                                        />
                                        {item?.questions.length} questions
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '15px !important',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                            color: 'text.primary'
                                        }}
                                    >
                                        <GroupIcon
                                            sx={{ color: 'primary.main' }}
                                        />
                                        {item?.votes.length} responses
                                    </Typography>
                                </Grid>
                        );
                    })}
                </Grid>
            </Box>
        </>
    );
};
