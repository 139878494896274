import React, { useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import Left from './left';
import BreadCrumb from '../../../common/components/Breadcrumb';
import { getAdminDashStats } from '../../../API/Statistics';
import jwtDecode from 'jwt-decode';

const breadcrumb = [
    {
        id: '01',
        breadItem: 'Dashboard'
    }
];

const Dashboard = () => {
    const token = localStorage.getItem('token');
    const user = token && jwtDecode(token);
    // console.log("user", user)
    useEffect(() => {
        getAdminDashStats()
        // .then((response) =>
            // console.log('Response fetched', response)
        // )
    }, []);

    return (
        <Box>
            <Grid container sm={12}>
                <Grid item sm={12}>
                    <Box my={1} mx={9}>
                        <Box mb={4}>
                            {' '}
                            <BreadCrumb breadItems={breadcrumb} />
                        </Box>
                        {/* <DataCard /> */}
                        <Left />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Dashboard;
