import { useEffect, useState } from 'react';
import {
    Box,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
    TextField,
    Button
} from '@mui/material';
import { useFormik } from 'formik';
import { Close, Upload } from '@mui/icons-material';
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import BreadCrumb from '../../../common/components/Breadcrumb';
import { AddusersBread } from '../../../utils/StaticData/Breadcrumbs/createCourse';
import Apiconfig from '../../../config/ApiConfig';
import './upload.css';
import { getAllCategories, getSubCategoriesById } from '../../../API/Category';
import { initialValues, validationSchema } from './FormikData';
import { AddUser } from '../../../API/Users';

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: '20rem',
            maxWidth: '40rem'
        }
    }
};

const AddUsers = () => {
    const token = localStorage.getItem('token');
    const [shouldRedirect, setRedirect] = useState(false);
    const [allCategories, setAllCategory] = useState([]);
    const [allSubCategory, setAllSubCategory] = useState([]);
    const [categoryId, setCategoryId] = useState('');
    const [individualUser, setIndividual] = useState(true);
    const [disabled, setDisabled] = useState(true);
    const navigate = useNavigate();
    const [multiple, setMultiple] = useState('');
    // const [addmore, setAddmore] = useState('');

    // ------start of submit--------------------------//////////////////////

    // ------End of submit----------------------------/////////////////////

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            shouldRedirect
                ? AddUser(formik.values, toast, navigate, 'addSingle')
                : AddUser(values, toast, resetForm);
        }
    });

    const clearFile = () => setMultiple('');
    const handleChange = (event) => {
        const filtreredSub = allCategories.filter(
            (cat) => cat.categoryName === event.target.value
        );
        // console.log('Filtered category', filtreredSub[0]);
        setCategoryId(filtreredSub[0]);

        getSubCategoriesById(filtreredSub[0]._id).then((response) =>
            setAllSubCategory(response)
        );
    };

    const handleMajor = (e) => {
        handleChange(e);
        formik.handleChange(e);
    };

    const handleMultiple = (e) => {
        // console.log('arshhhhhhh');
        const fileToUpload = e.target.files[0];
        // console.log('Upload File...... file', fileToUpload);
        setMultiple(fileToUpload);
    };
    const addIndividual = (e, individual) =>
        individual ? setIndividual(true) : setIndividual(false);
    const submitMultiple = async () => {
        const multiUsersData = new FormData();
        multiUsersData.append('excelfile', multiple);
        await Axios.post(Apiconfig.auth.multiRegister, multiUsersData, {
            headers: {
                Accept: '/',
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        });
    };
    useEffect(() => {
        getAllCategories().then((response) => {
            setAllCategory(response);
            // console.log('response', response);
        });
    }, [categoryId]);

    return (
        <Box px={{ xs: 4.5, md: 9 }} pb={2}>
            <BreadCrumb breadItems={AddusersBread} />
            <form>
                <Box className="category" my={6} maxWidth="md">
                    <FormControl fullWidth>
                        <InputLabel id="major">Major Category</InputLabel>
                        <Select
                            labelId="major"
                            id="major"
                            name="majorCategory"
                            value={formik.values.majorCategory}
                            label="Major Category"
                            onChange={handleMajor}
                            error={
                                formik.touched.majorCategory &&
                                Boolean(formik.errors.majorCategory)
                            }
                            MenuProps={MenuProps}
                            // helperText={formik.touched.majorCategory && formik.errors.majorCategory}
                        >
                            {allCategories?.map((obj, index) => (
                                <MenuItem
                                    value={obj.categoryName}
                                    id={index}
                                    key={obj._id}
                                >
                                    {' '}
                                    {obj.categoryName}{' '}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 3 }}>
                        <InputLabel id="sub">Sub Category</InputLabel>
                        {/* <FormControl fullWidth> */}
                        <Select
                            labelId="sub"
                            id="sub"
                            name="subCategory"
                            disabled={formik.values.majorCategory === ''}
                            value={formik.values.subCategory}
                            label="Sub Category"
                            onChange={(e) => {
                                setDisabled(e.target.value === '');
                                formik.handleChange(e);
                            }}
                            error={
                                formik.touched.subCategory &&
                                Boolean(formik.errors.subCategory)
                            }
                            MenuProps={MenuProps}
                            // helperText={formik.touched.subCategory && formik.errors.subCategory}
                        >
                            {allSubCategory?.map((obj) => (
                                <MenuItem
                                    value={obj.categoryName}
                                    id={obj._id}
                                    key={obj._id}
                                >
                                    {' '}
                                    {obj.categoryName}{' '}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box className="userType" maxWidth="md">
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        Type Of User
                    </Typography>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            // defaultValue="Student"
                            name="role"
                            value={formik.values.role}
                            onChange={formik.handleChange}
                            sx={{ mt: 2 }}
                            row
                            required
                            // error={formik.touched.userType && Boolean(formik.errors.userType)}
                            // helperText={formik.touched.userType && formik.errors.userType}
                        >
                            <FormControlLabel
                                value="student"
                                control={<Radio />}
                                label="Student"
                            />
                            <FormControlLabel
                                value="instructor"
                                control={<Radio />}
                                label="Instructor"
                            />
                            <FormControlLabel
                                value="labAssistant"
                                control={<Radio />}
                                label="Lab Assistant"
                            />
                        </RadioGroup>
                    </FormControl>
                    <Box display="flex" mt={5} mb={6}>
                        {' '}
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={(event) =>
                                addIndividual(event, 'individual')
                            }
                            disabled={individualUser}
                        >
                            Add Individual
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{ ml: 7 }}
                            color="secondary"
                            onClick={addIndividual}
                            disabled={!individualUser}
                        >
                            Add Multiple
                        </Button>
                    </Box>
                    {individualUser && (
                        <Box
                            className="fields"
                            display="flex"
                            flexDirection="column"
                        >
                            <TextField
                                label="Name"
                                value={formik.values.name}
                                // onChange={formik.handleChange}
                                onChange={(e) => {
                                    const trimmedValue = e.target.value.replace(
                                        /^\s+/,
                                        ''
                                    ); // Trim spaces before any character
                                    formik.handleChange(e);
                                    formik.setFieldValue('name', trimmedValue);
                                }}
                                name="name"
                                sx={{ mb: 3 }}
                                error={
                                    formik.touched.name &&
                                    Boolean(formik.errors.name)
                                }
                                helperText={
                                    formik.touched.name && formik.errors.name
                                }
                            />
                            <TextField
                                label="User Id"
                                value={formik.values.userId}
                                // onChange={formik.handleChange}
                                onChange={(e) => {
                                    const trimmedValue = e.target.value.replace(
                                        /^\s+/,
                                        ''
                                    ); // Trim spaces before any character
                                    formik.handleChange(e);
                                    formik.setFieldValue(
                                        'userId',
                                        trimmedValue
                                    );
                                }}
                                name="userId"
                                sx={{ mb: 3 }}
                                error={
                                    formik.touched.userId &&
                                    Boolean(formik.errors.userId)
                                }
                                helperText={
                                    formik.touched.userId &&
                                    formik.errors.userId
                                }
                            />
                            <TextField
                                label="User Name"
                                value={formik.values.userName}
                                // onChange={formik.handleChange}
                                onChange={(e) => {
                                    const trimmedValue = e.target.value.replace(
                                        /^\s+/,
                                        ''
                                    ); // Trim spaces before any character
                                    formik.handleChange(e);
                                    formik.setFieldValue(
                                        'userName',
                                        trimmedValue
                                    );
                                }}
                                name="userName"
                                sx={{ mb: 3 }}
                                error={
                                    formik.touched.userName &&
                                    Boolean(formik.errors.userName)
                                }
                                helperText={
                                    formik.touched.userName &&
                                    formik.errors.userName
                                }
                            />
                            <TextField
                                label="Email"
                                value={formik.values.email}
                                // onChange={formik.handleChange}
                                onChange={(e) => {
                                    const trimmedValue = e.target.value.replace(
                                        /^\s+/,
                                        ''
                                    ); // Trim spaces before any character
                                    formik.handleChange(e);
                                    formik.setFieldValue('email', trimmedValue);
                                }}
                                name="email"
                                sx={{ mb: 3 }}
                                error={
                                    formik.touched.email &&
                                    Boolean(formik.errors.email)
                                }
                                helperText={
                                    formik.touched.email && formik.errors.email
                                }
                            />
                            <TextField
                                label="Password"
                                value={formik.values.password}
                                // onChange={formik.handleChange}
                                onChange={(e) => {
                                    const trimmedValue = e.target.value.replace(
                                        /^\s+/,
                                        ''
                                    ); // Trim spaces before any character
                                    formik.handleChange(e);
                                    formik.setFieldValue(
                                        'password',
                                        trimmedValue
                                    );
                                }}
                                name="password"
                                sx={{ mb: 3 }}
                                error={
                                    formik.touched.password &&
                                    Boolean(formik.errors.password)
                                }
                                helperText={
                                    formik.touched.password &&
                                    formik.errors.password
                                }
                            />
                            <Box>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    disabled={disabled}
                                    onClick={() => {
                                        setRedirect(true);
                                        formik.handleSubmit();
                                    }}
                                >
                                    Save
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    disabled={disabled}
                                    onClick={formik.handleSubmit}
                                    sx={{ ml: 2 }}
                                >
                                    Save and add more
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Box>
                {individualUser || (
                    <>
                        {' '}
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ backgroundColor: 'secondary.light' }}
                            height="30vh"
                        >
                            {' '}
                            <Box
                                className="upload-btn-wrapper"
                                sx={{
                                    position: 'relative',
                                    overflow: 'hidden',
                                    display: 'inline-block'
                                }}
                            >
                                {multiple?.name || (
                                    <>
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                        >
                                            <Upload />
                                            <Button
                                                color="secondary"
                                                onClick={handleMultiple}
                                                className="import-btn"
                                            >
                                                Upload
                                            </Button>
                                        </Box>
                                        <input
                                            type="file"
                                            name="myfile"
                                            onChange={handleMultiple}
                                        />
                                    </>
                                )}
                            </Box>
                            {multiple?.name && (
                                <Close
                                    onClick={clearFile}
                                    sx={{ ml: 3 }}
                                    color="primary.light"
                                />
                            )}
                        </Box>
                        <Box textAlign="end" mt={3}>
                            <Button
                                onClick={submitMultiple}
                                variant="contained"
                                color="secondary"
                            >
                                Save
                            </Button>
                        </Box>
                    </>
                )}
            </form>
            <ToastContainer />
        </Box>
    );
};

export default AddUsers;
