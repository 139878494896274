import React from 'react';
import {
    Stack,
    IconButton,
    Avatar,
    Button,
    Menu,
    MenuItem,
    Input,
    TextField,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Grid,
    Select,
    InputLabel,
    Box,
    Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import avatar from '../../../assets/images/avatar.jpg';
import { changeLogo } from '../../../API/uiApi';
import Apiconfig from '../../../config/ApiConfig';
import { Logout } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import PollIcon from '@mui/icons-material/Poll';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import './index.css';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { createPolls, createSurveys } from '../../../API/Course';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { AttachFile } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { store } from '../../../store';
import 'react-toastify/dist/ReactToastify.css';
import { setCurrentScreenLoading } from '../../../containers/Pages/CreateCourse/Slices/currentScreen';

const LogoLayout = (props) => {
    const navigate = useNavigate();
    const { onPathChange } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [file, setFile] = useState('');
    const [logo, setLogo] = useState(null);
    const open = Boolean(anchorEl);
    const [show, setShow] = useState(false);
    const [pollOpen, setPollOpen] = useState(false);
    const [createPollOpen, setCreatePollOpen] = useState(false);
    const [checked, setChecked] = useState(false);
    const [progress, setProgress] = useState(0);
    const [prompt, setPrompt] = useState('');
    const [options, setOptions] = useState([]);
    const [indexCounter, setIndexCounter] = useState(1);
    const [textFields, setTextFields] = useState([]);
    const [disabledSubmit, setDisabledSubmit] = useState(true);
    const [disablePollCreate, setDisablePollCreate] = useState(true);
    const [disableUpload, setDisableUpload] = useState(true);

    const handleChecked = () => {
        setChecked(!checked);
        checked ? setProgress(0) : setProgress(100);
    };

    const handlePollOpen = () => {
        setPollOpen(true);
        setAnchorEl(null);
    };

    const handlePollClose = () => {
        setPollOpen(false);
    };

    const handleCreatePollOpen = () => {
        setCreatePollOpen(true);
        setPollOpen(false);
    };

    const handleCreatePollClose = () => {
        setCreatePollOpen(false);
    };
    const [surveyShow, setSurveyShow] = useState(false);
    const [createSurveyShow, setCreateSurveyShow] = useState(false);

    const questionId = useSelector((state) => state?.currentScreen?.id);
    const sectionId = useSelector(
        (state) => state?.currentCourse?.currentSectionId
    );

    const handleCreateSurveyOpen = () => {
        setCreateSurveyShow(true);
        setSurveyShow(false);
    };
    const handleCreateSurveyClose = () => {
        setCreateSurveyShow(false);
    };

    const [formData, setFormData] = useState({
        title: '',
        questions: [
            {
                questionType: 'mcq',
                question: '',
                options: {
                    option1: '',
                    option2: '',
                    option3: '',
                    option4: ''
                }
                // answer: ''
            }
        ]
    });

    // const handleChange = (e, index) => {
    //     const { name, value } = e.target;
    //     const data = [...formData.questions];

    //     if (name.slice(0, 6) === 'option') {
    //         data[index].options[name] = value;
    //         setFormData({ ...formData, questions: data });
    //     } else {
    //         data[index][name] = value;
    //         setFormData({ ...formData, questions: data });
    //     }
    // };
    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const trimmedValue = value.replace(/^\s+/, ''); // Trim spaces before any character
        const data = [...formData.questions];
        if (name.slice(0, 6) === 'option') {
            data[index].options[name] = trimmedValue;
            setFormData({ ...formData, questions: data });
        } else {
            data[index][name] = trimmedValue;
            setFormData({ ...formData, questions: data });
        }
    };

    // console.log("formData", formData)
    const addQuestion = () => {
        if (formData?.questions?.length <= 4) {
            // console.log('Form data in handlesubmit', formData);

            const newForm = {
                questionType: 'mcq',
                question: '',
                options: {
                    option1: '',
                    option2: '',
                    option3: '',
                    option4: ''
                }
            };
            formData.questions.push(newForm);
            setFormData({ ...formData });
        } else {
            toast.error(
                'You have reached the maximum limit to create questions'
            );
        }
    };

    // const deleteQuestion = (index) => {
    //     const updatedQuestions = [...formData.questions];
    //     updatedQuestions.splice(index, 1);
    //     setFormData({
    //         ...formData,
    //         questions: updatedQuestions
    //     });
    // };
    const deleteQuestion = (index) => {
        if (formData.questions.length > 1) {
            const updatedQuestions = [...formData.questions];
            updatedQuestions.splice(index, 1);

            setFormData((prevFormData) => ({
                ...prevFormData,
                questions: updatedQuestions
            }));
        }
    };

    const handleClickSurveyOpen = () => {
        setSurveyShow(true);
        setAnchorEl(null);
    };
    const handleSurveyShow = () => {
        setSurveyShow(false);
        setAnchorEl(null);
    };
    const handleClickOpen = () => {
        setShow(true);
    };

    const handleShow = () => {
        setShow(false);
        setDisableUpload(true);
        setFile('');
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        onPathChange(logo);

        setAnchorEl(null);
    };

    const handleSubmitSurvey = async () => {
        // console.log('clicked');
        // console.log('Form data in handlesubmit', formData);
        let isEmpty;
        let isOptions;

        isEmpty = Object.values(formData).some((x) => x === null || x === '');
        isOptions = Object.values(formData.options || {}).some(
            (x) => x === null || x === ''
        );
        if (isEmpty || isOptions) {
            // console.log('Empty field', isEmpty, isOptions);
            toast.error('Please fill all the fields');
        } else {
            await createSurveys(formData);
            setFormData({
                title: '',
                questions: [
                    {
                        questionType: 'mcq',
                        question: '',
                        options: {
                            option1: '',
                            option2: '',
                            option3: '',
                            option4: ''
                        }
                    }
                ]
            });
            setCreateSurveyShow(false);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();

        formData.append('logo', file);

        changeLogo(formData).then((res) => {
            setLogo(Apiconfig?.url + res.data.newLogo.logo);
        });
        setDisableUpload(true);
        setFile('');
        setAnchorEl(null);
        toast.success('Logo updated successfully!');
    };

    onPathChange(logo);

    const logout = () => {
        localStorage.removeItem('token');
        navigate('/');
    };

    const handleAddOption = () => {
        const newOptionName = `option${indexCounter}`;
        const newOption = { [newOptionName]: '' };
        setOptions((prevOptions) => {
            const updatedOptions = [{ ...prevOptions[0], ...newOption }];
            return updatedOptions;
        });
        const InputDiv = (
            <Box
                key={newOptionName} // Add a key for tracking elements in an array
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: '100%',
                    flexDirection: 'column'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}
                >
                    <FormControlLabel
                        value=""
                        control={
                            <Radio checked={checked} onClick={handleChecked} />
                        }
                        label={
                            <>
                                <TextField
                                    sx={{
                                        '& fieldset': { border: 'none' }
                                    }}
                                    size="small"
                                    autoFocus
                                    InputLabelProps={{ shrink: false }}
                                    id={newOptionName}
                                    name={newOptionName}
                                    value={options[newOptionName]}
                                    onChange={(e) => {
                                        const newValue = e.target.value.replace(
                                            /^\s+/,
                                            ''
                                        );
                                        setOptions((prevOptions) => {
                                            const updatedOption = {
                                                ...prevOptions[indexCounter],
                                                [newOptionName]: newValue
                                            };
                                            const updatedOptions = [
                                                {
                                                    ...prevOptions[0],
                                                    ...updatedOption
                                                }
                                            ];
                                            // console.log(
                                            //     updatedOption,
                                            //     updatedOptions
                                            // );
                                            if (options[newOptionName] !== '') {
                                                setDisablePollCreate(false);
                                            } else {
                                                setDisablePollCreate(true);
                                            }
                                            return updatedOptions;
                                        });
                                    }}
                                />
                            </>
                        }
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 2
                        }}
                    >
                        <Typography>{progress} %</Typography>
                        <IconButton
                            aria-label="delete"
                            onClick={() => {
                                handleDeletePollOption(newOptionName);
                                // console.log('deleteQuestion', newOptionName);
                            }}
                        >
                            <DeleteIcon
                                style={{
                                    color: 'red'
                                }}
                            />
                        </IconButton>
                    </Box>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress
                        variant="determinate"
                        value={progress}
                        sx={{
                            '& .MuiLinearProgress-bar': {
                                borderRadius: 2
                            }
                        }}
                    />
                </Box>
            </Box>
        );
        setTextFields((prevTextFields) => [...prevTextFields, InputDiv]);
        setIndexCounter((prevCounter) => prevCounter + 1);
    };
    const handleDeletePollOption = (optionName) => {
        setOptions((prevOptions) => {
            const updatedOptions = { ...prevOptions[0] };
            delete updatedOptions[optionName];
            return [updatedOptions];
        });

        setTextFields((prevTextFields) => {
            const updatedTextFields = prevTextFields.filter(
                (field) => field.key !== optionName
            );
            return updatedTextFields;
        });
    };
    const formdata = {
        question: prompt,
        options: options
    };

    const createPoll = async () => {
        const res = await createPolls(formdata);
        // console.log('res of createPoll', res);
        setCreatePollOpen(false);
    };

    const getCurrentQuestion = async (response) => {
        // console.log('all questions fetched', response);

        setFormData({
            title: response?.data?.excercise?.title,
            questions: [
                {
                    questionType: response?.data?.excercise?.questionType,
                    question: response?.data?.excercise?.question,
                    options: {
                        option1: response?.data?.excercise?.options[0]?.a,
                        option2: response?.data?.excercise?.options[1]?.b,
                        option3: response?.data?.excercise?.options[2]?.c,
                        option4: response?.data?.excercise?.options[3]?.d
                    }
                    // answer: response?.data?.excercise?.answer
                }
            ]
        });
    };

    const resetQuestions = () =>
        setFormData({
            title: '',
            questions: [
                {
                    questionType: 'mcq',
                    question: '',
                    options: {
                        option1: '',
                        option2: '',
                        option3: '',
                        option4: ''
                    }
                    // answer: ''
                }
            ]
        });

    useEffect(() => {
        questionId ? getCurrentQuestion() : resetQuestions();
    }, [questionId]);

    return (
        <Stack
            direction="row"
            alignItems="center"
            spacing={{ xs: 0.5, sm: 1.5 }}
            sx={{ color: 'black' }}
        >
            <Button
                onClick={handleClick}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                sx={{ color: 'black' }}
                type="file"
            >
                <IconButton>
                    <Avatar alt="Profile" src={avatar} sx={{ mr: '5px' }} />
                </IconButton>{' '}
            </Button>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
            >
                <MenuItem
                    onClick={handleClickOpen}
                    sx={{ color: 'text.primary' }}
                >
                    <EditIcon sx={{ mx: 1 }} />
                    Change logo
                </MenuItem>
                <MenuItem
                    sx={{ color: 'text.primary' }}
                    onClick={handlePollOpen}
                >
                    <PollIcon sx={{ mx: 1 }} />
                    Polls
                </MenuItem>
                <MenuItem
                    onClick={handleClickSurveyOpen}
                    sx={{ color: 'text.primary' }}
                >
                    <AssignmentIcon sx={{ mx: 1 }} />
                    Survey
                </MenuItem>
                <MenuItem onClick={logout} sx={{ color: 'text.primary' }}>
                    <Logout sx={{ mx: 1 }} /> Logout
                </MenuItem>
            </Menu>

            {/* ******************create poll dialogs start******************** */}

            <Dialog
                open={pollOpen}
                maxWidth="xs"
                fullWidth
                TransitionComponent={Transition}
                keepMounted
                onClose={handlePollClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Poll</DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start'
                            }}
                        >
                            <Button
                                color="secondary"
                                onClick={handleCreatePollOpen}
                            >
                                Create
                            </Button>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start'
                            }}
                        >
                            <Button
                                color="secondary"
                                onClick={() => {
                                    setPollOpen(false);
                                    navigate('/dashboard/polls');
                                }}
                            >
                                View Results
                            </Button>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            <Dialog
                open={createPollOpen}
                maxWidth="xs"
                fullWidth
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCreatePollClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Poll</DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                width: '100%',
                                flexDirection: 'column'
                            }}
                        >
                            <TextField
                                multiline
                                sx={{
                                    '& fieldset': { border: 'none' },
                                    my: 2
                                }}
                                name="title"
                                label={prompt ? '' : 'Set Prompt'}
                                value={prompt}
                                onChange={(e) =>
                                    setPrompt(
                                        e.target.value.replace(/^\s+/, '')
                                    )
                                }
                                size="small"
                                InputLabelProps={{ shrink: false }}
                                InputProps={{ style: { fontWeight: 500 } }}
                            />
                        </Box>
                        {options.length === 0 && (
                            <Typography>No options yet</Typography>
                        )}
                        {textFields}
                    </DialogContentText>
                    <Box mt={2}>
                        <Button
                            color="secondary"
                            onClick={handleAddOption}
                            // sx={{ paddingLeft: '0px !important' }}
                        >
                            Add Option
                        </Button>
                    </Box>
                </DialogContent>
                {Object.keys(options).length !== 0 && (
                    <DialogActions
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%'
                        }}
                    >
                        <Button
                            disabled={disablePollCreate}
                            color="secondary"
                            variant="contained"
                            onClick={createPoll}
                        >
                            Create
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
            {/* ********************create poll dialogs end************************** */}

            {/* ******************change logo dialog start******************** */}

            {show && (
                <div>
                    <Dialog open={open} onClose={handleShow}>
                        <DialogTitle>Change Logo</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Please Choose the image you wish to upload
                            </DialogContentText>
                            <Stack
                                className="upload-btn-wrapper"
                                style={{
                                    // marginTop:
                                    //     '-15px',
                                    display: 'flex',
                                    alignItems: 'center'
                                    // gap: 10
                                }}
                            >
                                <IconButton className="btn">
                                    <AddPhotoAlternateIcon fontSize="large" />
                                    <input
                                        type="file"
                                        name="myfile"
                                        onChange={(e) => {
                                            if (
                                                !(
                                                    e.target.files[0] &&
                                                    (e.target.files[0].type ===
                                                        'image/jpeg' ||
                                                        e.target.files[0]
                                                            .type ===
                                                            'image/jpg' ||
                                                        e.target.files[0]
                                                            .type ===
                                                            'image/png')
                                                )
                                            ) {
                                                // Show error toast
                                                setFile('');
                                                setAnchorEl(null);
                                                toast.error(
                                                    'Please! upload images only.'
                                                );
                                            } else {
                                                // Set media if the condition is satisfied
                                                // console.log(
                                                //     'name12',
                                                //     e.target.files[0]
                                                // );
                                                setFile(e.target.files[0]);
                                                const isEmptyPlaceholder =
                                                    e.target.files[0] === '';
                                                setDisableUpload(
                                                    isEmptyPlaceholder ||
                                                        !e.target.files[0]
                                                );
                                                // setFile('');
                                                //  setFile(e.target.files[0]})
                                            }
                                        }}
                                        // required
                                    />
                                </IconButton>
                                <Typography
                                    variant="body1"
                                    style={{ textTransform: 'uppercase' }}
                                >
                                    {file?.name}
                                </Typography>
                                {/* <Input
                                    type="file"
                                    onChange={(e) => {
                                        if (
                                            !(
                                                e.target.files[0] &&
                                                (e.target.files[0].type ===
                                                    'image/jpeg' ||
                                                    e.target.files[0].type ===
                                                        'image/jpg' ||
                                                    e.target.files[0].type ===
                                                        'image/png')
                                            )
                                        ) {
                                            // Show error toast
                                            setFile('');
                                            setAnchorEl(null);
                                            toast.error(
                                                'Please! upload images only.'
                                            );
                                        } else {
                                            // Set media if the condition is satisfied
                                            console.log(
                                                'name12',
                                                e.target.files[0]
                                            );
                                            setFile(e.target.files[0]);
                                            const isEmptyPlaceholder =
                                                e.target.files[0] === '';
                                            setDisableUpload(
                                                isEmptyPlaceholder ||
                                                    !e.target.files[0]
                                            );
                                            // setFile('');
                                            //  setFile(e.target.files[0]})
                                        }
                                    }}
                                /> */}
                            </Stack>
                            <Stack sx={{ alignItems: 'center' }}>
                                <Typography variant="overline" fontSize="10px">
                                    *Image should be in .jpeg, .jpg or .png
                                </Typography>
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleShow}>Cancel</Button>
                            <Button
                                disabled={disableUpload}
                                onClick={handleSubmit}
                            >
                                Upload
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )}

            {/* ******************change logo dialog start******************** */}

            {/* ******************create survey dialogs start******************** */}

            <Dialog
                open={surveyShow}
                maxWidth="xs"
                fullWidth
                TransitionComponent={Transition}
                keepMounted
                onClose={handleSurveyShow}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Survey</DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start'
                            }}
                        >
                            <Button
                                color="secondary"
                                onClick={handleCreateSurveyOpen}
                            >
                                Create
                            </Button>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start'
                            }}
                        >
                            <Button
                                color="secondary"
                                onClick={() => {
                                    setSurveyShow(false);
                                    navigate('/dashboard/survey');
                                }}
                            >
                                View Results
                            </Button>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            <Dialog
                open={createSurveyShow}
                onClose={handleCreateSurveyClose}
                maxWidth="xs"
                fullWidth
                TransitionComponent={Transition}
                keepMounted
            >
                <DialogTitle>Survey Form</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please make a question bank you wish to address
                    </DialogContentText>
                    <Box my={3}>
                        <Stack spacing={3}>
                            <TextField
                                fullWidth
                                multiline
                                label="title"
                                name="title"
                                value={formData?.title}
                                //   onChange={(e) => {
                                //       handleChange(e);
                                //    }}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        title: e.target.value.trimStart()
                                    })
                                }
                            />
                            {formData?.questions?.map((currentItem, index) => (
                                <TextField
                                    key={index}
                                    id={`question-${index}`}
                                    fullWidth
                                    multiline
                                    disabled
                                    label={`Question ${index + 1}`}
                                    InputLabelProps={{
                                        style: {
                                            paddingLeft: '20px',
                                            color: '#09111C'
                                        }
                                    }}
                                    InputProps={{
                                        style: {
                                            display: 'block',
                                            padding: '20px',
                                            paddingBottom: '0px',
                                            // borderLeft: '12px solid #5292DD',
                                            boxShadow:
                                                '0px 1px 4px 1px rgba(0, 0, 0, 0.15)'
                                        },
                                        startAdornment: (
                                            <Box
                                                display="flex"
                                                gap={2}
                                                flexDirection="column"
                                            >
                                                <FormControl>
                                                    <Stack
                                                        sx={{
                                                            justifyContent:
                                                                'end',
                                                            alignItems: 'end'
                                                        }}
                                                    >
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => {
                                                                // console.log(
                                                                //     'deleteQuestion',
                                                                //     index
                                                                // );
                                                                deleteQuestion(
                                                                    index
                                                                );
                                                            }}
                                                            disabled={
                                                                formData
                                                                    ?.questions
                                                                    ?.length <=
                                                                1
                                                            }
                                                        >
                                                            <DeleteIcon
                                                                style={{
                                                                    color: 'red'
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Stack>
                                                    <InputLabel id="demo-simple-select-label">
                                                        Question type
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="questionType"
                                                        label="Question Type"
                                                        value={
                                                            currentItem.questionType
                                                        }
                                                        onChange={(e) =>
                                                            handleChange(
                                                                e,
                                                                index
                                                            )
                                                        }
                                                    >
                                                        <MenuItem value="mcq">
                                                            Multiple choice
                                                            question
                                                        </MenuItem>
                                                        <MenuItem value="true/false">
                                                            True/False
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    label="Question"
                                                    name="question"
                                                    value={
                                                        formData?.questions
                                                            ?.question
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(e, index)
                                                    }
                                                    // onChange={(e) =>
                                                    //     setFormData({
                                                    //         ...formData?.questions?.question,
                                                    //             question: e.target.value.trimStart()
                                                    //     })
                                                    // }
                                                />
                                                {currentItem?.questionType ===
                                                'mcq' ? (
                                                    <>
                                                        <TextField
                                                            fullWidth
                                                            multiline
                                                            label="Option a"
                                                            name="option1"
                                                            value={
                                                                currentItem
                                                                    ?.options
                                                                    ?.option1
                                                            }
                                                            onChange={(e) => {
                                                                handleChange(
                                                                    e,
                                                                    index
                                                                );
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                        />
                                                        <TextField
                                                            fullWidth
                                                            multiline
                                                            label="Option b"
                                                            name="option2"
                                                            value={
                                                                currentItem
                                                                    ?.options
                                                                    ?.option2
                                                            }
                                                            onChange={(e) => {
                                                                handleChange(
                                                                    e,
                                                                    index
                                                                );
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                        />
                                                        <TextField
                                                            fullWidth
                                                            multiline
                                                            label="Option c"
                                                            name="option3"
                                                            value={
                                                                currentItem
                                                                    ?.options
                                                                    ?.option3
                                                            }
                                                            onChange={(e) => {
                                                                handleChange(
                                                                    e,
                                                                    index
                                                                );
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                        />
                                                        <TextField
                                                            fullWidth
                                                            multiline
                                                            label="Option d"
                                                            name="option4"
                                                            value={
                                                                currentItem
                                                                    ?.options
                                                                    ?.option4
                                                            }
                                                            onChange={(e) => {
                                                                handleChange(
                                                                    e,
                                                                    index
                                                                );
                                                                if (
                                                                    currentItem
                                                                        ?.options
                                                                        ?.option4 ===
                                                                        '' ||
                                                                    currentItem
                                                                        ?.options
                                                                        ?.option3 ===
                                                                        '' ||
                                                                    currentItem
                                                                        ?.options
                                                                        ?.option2 ===
                                                                        '' ||
                                                                    currentItem
                                                                        ?.options
                                                                        ?.option1 ===
                                                                        ''
                                                                ) {
                                                                    setDisabledSubmit(
                                                                        true
                                                                    );
                                                                } else {
                                                                    setDisabledSubmit(
                                                                        false
                                                                    );
                                                                }
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                        />
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </Box>
                                        )
                                    }}
                                />
                            ))}
                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        variant="text"
                        onClick={addQuestion}
                    >
                        {' '}
                        + Add Question
                    </Button>
                    <Button
                        color="error"
                        variant="outlined"
                        onClick={handleCreateSurveyClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={disabledSubmit}
                        color="secondary"
                        variant="contained"
                        onClick={handleSubmitSurvey}
                    >
                        Submit
                    </Button>
                </DialogActions>
                <ToastContainer />
            </Dialog>

            {/* ******************create poll dialogs end******************** */}

            <ToastContainer />
        </Stack>
    );
};

LogoLayout.propTypes = {
    onPathChange: PropTypes.func.isRequired
};

export default LogoLayout;
