import { useEffect, useState } from 'react';
import { Stack, Typography, Box, Grid, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { AddBox, PersonAddAlt } from '@mui/icons-material';
// import PropTypes from 'prop-types';
import { instructorRecentCourses } from '../../../API/Course';
import { getAdminDashStats } from '../../../API/Statistics';
import CourseDetailCard from './courseDetailCard';

import ListView from './ListView';
import CourseStatus from './CourseStatus';
import Infographics1 from './Graph';

const Left = () => {
    const theme = useTheme();
    const [recentCourses, setRecent] = useState();
    const [statistics, setStatistics] = useState();

    const getRecentCourses = async () => {
        await instructorRecentCourses().then((response) =>{
            setRecent(response)
            // console.log("instructorRecentCourses", response)
        });
    };
    const getAdminStatistics = async () => {
        await getAdminDashStats().then((response) => setStatistics(response));
    };

    useEffect(() => {
        getRecentCourses();
        getAdminStatistics();
    }, []);

    return (
        <Stack>
            <Grid container spacing={2}>
                {statistics?.map((item, index) => (
                    <Grid
                        item
                        key={item?.name}
                        sm={6}
                        xs={12}
                        md={4}
                        id={item?.number}
                    >
                        <CourseDetailCard item={item} />
                    </Grid>
                ))}
            </Grid>
            <Stack direction="row">
                <Stack>
                    <Typography mt={4} mb={2}>
                        Quick Actions
                    </Typography>
                    <Stack>
                        <Stack direction={{ xs: 'column', md: 'row' }}>
                            <Link to="/dashboard/users/add-users">
                                {' '}
                                <Box
                                    variant="contained"
                                    display="flex"
                                    alignItems="center"
                                    pl={3}
                                    boxShadow={8}
                                    sx={{
                                        width: '288px',
                                        height: '71px',
                                        mr: 6,
                                        backgroundColor: 'secondary.main',
                                        borderRadius: '4px'
                                    }}
                                >
                                    <PersonAddAlt
                                        color="white"
                                        sx={{ fontSize: 40, mr: 2 }}
                                        fontSize="40px"
                                    />
                                    <Typography variant="body" color="white">
                                        {' '}
                                        Add User
                                    </Typography>
                                </Box>
                            </Link>

                            <Link to="/dashboard/courses/create-course">
                                <Box
                                    variant="contained"
                                    display="flex"
                                    alignItems="center"
                                    pl={3}
                                    boxShadow={8}
                                    sx={{
                                        width: '288px',
                                        height: '71px',
                                        mr: 6,
                                        backgroundColor: 'secondary.main',
                                        borderRadius: '4px',
                                        [theme.breakpoints.down('md')]: {
                                            mt: 1
                                        }
                                    }}
                                >
                                    <AddBox
                                        color="white"
                                        sx={{ fontSize: 40, mr: 2 }}
                                    />
                                    <Typography variant="body" color="white">
                                        {' '}
                                        Add Course
                                    </Typography>

                                    {/* </Button> */}
                                </Box>
                            </Link>
                        </Stack>
                        <Box
                            mt={2}
                            sx={{
                                [theme.breakpoints.up('sm')]: {
                                    display: 'none'
                                }
                            }}
                        >
                            {' '}
                            <CourseStatus />
                        </Box>
                        <Box
                            mt={2}
                            sx={{
                                [theme.breakpoints.up('md')]: {
                                    display: 'none'
                                }
                            }}
                        >
                            {' '}
                            <Infographics1 />
                        </Box>
                        <Box mt={8}>
                            <Typography variant="h4">
                                Recently added courses
                            </Typography>
                            {recentCourses?.length > 0 ? (
                                recentCourses.map((item, index) => (
                                    <ListView item={item} key={item?._id} />
                                ))
                            ) : (
                                <Typography variant="body1" sx={{ mt: 3 }}>
                                    No Course Created yet
                                </Typography>
                            )}
                        </Box>
                    </Stack>
                </Stack>
                {/* <Box
                    mt={2}
                    pl={2}
                    flexDirection="column"
                    sx={{
                        [theme.breakpoints.down('sm')]: { display: 'none' }
                    }}
                >
                    {' '}
                    <CourseStatus />
                </Box> */}
                <Box
                    width="60%"
                    m={6}
                    mr={0}
                    sx={{ [theme.breakpoints.down('md')]: { display: 'none' } }}
                >
                    <Infographics1 />
                </Box>
            </Stack>
        </Stack>
    );
};

export default Left;
