/* global chrome */
import {
    Box,
    Button,
    Stack,
    Chip,
    Divider,
    useTheme,
    Typography,
    IconButton,
    Menu,
    Grid,
    Tab
} from '@mui/material';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FilterList, ViewModule, ViewList } from '@mui/icons-material';
import { store } from '../../../store';
import { AddBox } from '@mui/icons-material';
import BreadCrumb from '../../../common/components/Breadcrumb';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import GridView from '../../../common/components/GridView/index';
import Apiconfig from '../../../config/ApiConfig';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {
    getAllCategories,
    getCourseByMajorCategory
} from '../../../utils/StaticData/Course/index';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListView from '../../../common/components/ListView';
import { myCourses } from '../../../API/Course';
import PendingRequest from '../pendingRequest';

const breadcrumb = [
    {
        id: '01',
        breadItem: 'All Courses'
    }
];

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: '20rem',
            maxWidth: '20rem'
            // overflow:"scroll",
        }
    }
};

const Dashboard = () => {
    const [data, setData] = useState([]);

    const [anchorElUser, setAnchorElUser] = useState(null);
    const [count, setCount] = useState(1);
    const [value, setValue] = useState('1');
    const [page, setPage] = useState(1);
    const [isLoading, setIsloading] = useState(false);
    const [fake, setFake] = useState(false);
    const handleView = (view) => setView(view);
    const [dataView, setView] = useState('gridView');
    const [allCategories, setAllCategories] = useState('');
    const [allSubCategory, setAllSubCategory] = useState([]);
    const [selectedMajor, setSelectedMajor] = useState('');
    const [selectedSubCat, setSelectedSubCat] = useState('');
    const [categoryId, setCategoryId] = useState('');

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleCategories = async (e) => {
        // handleChange(e);
        // if (e.target.name === 'subCategory') {
        //     setSelectedSubCat(e.target.value);
        // } else if (e.target.name === 'majorCategory') {
        setSelectedMajor(e.target.value);
        // console.log('value inside target', e.target.value);
        const value = e.target.value;
        const res = await getCourseByMajorCategory(value);

        // console.log('res of majorcategory', res);
        setData(res);
        setAnchorElUser(null);
        //     const filtreredSub = allCategories.filter(
        //         (cat) => cat.categoryName === e.target.value
        //     );
        //     console.log('Filtered category', filtreredSub[0]);
        //     // setCategoryId(filtreredSub[0]);

        //     // getCourseBySubCategory(filtreredSub[0]._id).then((response) =>
        //     //     setAllSubCategory(response)
        //     // );
        // }
    };

    const fetchMajorCategories = () => {
        getAllCategories().then((res) => {
            setAllCategories(res);
        });
    };
    // const fetchSubCategories = () => {
    //     getCourseBySubCategory().then(() => {
    //         setAllSubCategory();
    //     });
    // };
    useEffect(() => {
        fetchMajorCategories();
    }, []);

    const storeState = store.getState();
    // console.log('Redux state', storeState);
    const getConversations = async () => {
        try {
            const res = await Axios.get(Apiconfig.course.getAllCourse);
            // console.log('conversation', res.data.data);
            setData(res.data.data);
        } catch (err) {
            console.log(err);
        }
    };
    const checkForExtension = () => {
        // console.log(chrome.runtime, 'chrome');
    };
    useEffect(() => {
        checkForExtension();
        getConversations();
    }, []);

    const theme = useTheme();

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box display={{ md: 'none' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                            onChange={handleTabChange}
                            textColor="primary.light"
                            TabIndicatorProps={{
                                sx: { backgroundColor: 'secondary.main' }
                            }}
                        >
                            <Tab label="Students" value="1" />
                            <Tab label="Courses" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Box sx={{ minHeight: 'calc(100vh - 201px)' }}>
                            <PendingRequest fake={fake} fun={setFake} />
                        </Box>
                    </TabPanel>
                    <TabPanel value="2">
                        <Box mb={1.5}>
                            {' '}
                            <BreadCrumb breadItems={breadcrumb} />
                        </Box>
                        <Divider
                            sx={{
                                [theme.breakpoints.up('sm')]: {
                                    display: 'none'
                                }
                            }}
                        />
                        <Box
                        //  mx={{ xs: 4, md: 9 }}
                        >
                            {/* <Box
                    display="flex"
                    flexDirection={{ xs: 'row-reverse', md: 'column' }}
                    justifyContent={{ xs: 'space-between' }}
                    alignItems={{ md: 'end' }}
                    // mb={{ xs: 1 }}
                    mt={{ xs: 1.5 }}
                > */}{' '}
                            {/* <Link to="/dashboard">
                        <Button variant="contained" color="secondary">
                            My Courses
                        </Button>
                    </Link> */}
                            {/* <Button
                        variant="text"
                        color="secondary"
                        startIcon={<FilterList className="view-icon" />}
                    >
                        Filter
                    </Button>
                </Box> */}
                            <Box mt={6}>
                                {/* <Stack direction="row" spacing={1}> */}
                                {/* <Chip label="Web Development" /> */}
                                <Link to="/dashboard/courses/create-course">
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                    >
                                        {' '}
                                        Add Course
                                    </Button>
                                </Link>
                                {/* </Stack> */}
                            </Box>
                            {/* <GridView data={data} browseCourses /> */}
                            {/* {data?.length !== 0 && ( */}
                            <Box textAlign="end" mb={3} pr={{ md: 0, sm: 3 }}>
                                <Box
                                    // mt={4}
                                    sx={{
                                        [theme.breakpoints.down('sm')]: {
                                            display: 'none'
                                        },
                                        Zindex: 200,
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    {/* <BasicExampleDataGrid /> */}
                                    {/* <Button onClick={filterAllCategories} color="secondary" variant='outlined'>
                                                All
                                            </Button> */}

                                    <Box mr={2}>
                                        <FormControl>
                                            {/* <IconButton
                                        onClick={handleOpenUserMenu}
                                        sx={{ p: 0 }}
                                    >
                                        <FilterList color="secondary" />{' '}
                                    </IconButton> */}

                                            {/* <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    > */}
                                            <MenuItem
                                                sx={{
                                                    width: 250,
                                                    top: '-60px'
                                                }}
                                            >
                                                <FormControl fullWidth>
                                                    <InputLabel id="category-select-label">
                                                        Major Category
                                                    </InputLabel>
                                                    <Select
                                                        labelId="category-select-label"
                                                        id="demo-simple-select"
                                                        name="majorCategory"
                                                        value={selectedMajor}
                                                        label="Major Category"
                                                        onChange={
                                                            handleCategories
                                                        }
                                                        MenuProps={MenuProps}
                                                    >
                                                        {allCategories &&
                                                            allCategories?.map(
                                                                (
                                                                    obj,
                                                                    index
                                                                ) => (
                                                                    <MenuItem
                                                                        value={
                                                                            obj.categoryName
                                                                        }
                                                                        key={
                                                                            index
                                                                        }
                                                                        subcategories={
                                                                            obj.subCategories
                                                                        }
                                                                    >
                                                                        {
                                                                            obj.categoryName
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            </MenuItem>
                                            {/* </Menu> */}
                                            {/* <Grid item>
                                    <FormControl fullWidth>
                                        <InputLabel id="subCategory">
                                            Sub Category
                                        </InputLabel>
                                        <Select
                                            labelId="subCategory"
                                            id="subCategory"
                                            name="subCategory"
                                            value={selectedSubCat}
                                            label="Sub Category"
                                            onChange={handleCategories}
                                        >
                                            {allSubCategory?.map(
                                                (obj) => (
                                                    <MenuItem
                                                        value={obj.categoryName}
                                                    >
                                                        {obj.categoryName}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid> */}
                                            {/* </Select> */}
                                        </FormControl>
                                    </Box>
                                    {/* <ViewModule
                                        className="view-icon"
                                        sx={
                                            dataView === 'gridView'
                                                ? {
                                                      color: 'secondary.main'
                                                  }
                                                : {
                                                      color: 'primary.light'
                                                  }
                                        }
                                        onClick={() => handleView('gridView')}
                                    />
                                    <ViewList
                                        className="view-icon listIcon"
                                        sx={
                                            dataView === 'listView'
                                                ? {
                                                      color: 'secondary.main'
                                                  }
                                                : {
                                                      color: 'primary.light'
                                                  }
                                        }
                                        onClick={() => handleView('listView')}
                                    /> */}
                                </Box>
                            </Box>
                            {/* )} */}
                            <Box
                                mt={{ xs: 1.5 }}
                                // md={user?.role === 'admin' && 12}
                            >
                                {dataView === 'gridView' ? (
                                    isLoading ? (
                                        <Box textAlign="center">
                                            <CircularProgress />
                                        </Box>
                                    ) : (
                                        <GridView
                                            data={data}
                                            page={page}
                                            setPage={setPage}
                                            count={count}
                                            setCount={setCount}
                                            myCourses={myCourses}
                                            myCourse
                                        />
                                    )
                                ) : (
                                    <ListView
                                        data={data}
                                        page={page}
                                        setPage={setPage}
                                        count={count}
                                        setCount={setCount}
                                        myCourses={myCourses}
                                        myCourse
                                    />
                                )}
                            </Box>
                        </Box>
                    </TabPanel>
                </TabContext>
            </Box>
            <Box display={{ sm: 'none', xs: 'none', md: 'block', lg: 'block' }}>
                <Grid
                    container
                    justifyContent={'space-between'}
                    sx={{
                        px: { md: 10 },
                        // pl:{ md: 5 },
                        // pt: 1,
                        width: '97%',
                        margin: 'auto',
                        // px: 10,
                        minHeight: 'calc(100vh - 188px)'
                    }}
                >
                    <Grid
                        item
                        md={2.5}
                        sm={12}
                        xs={12}
                        px={{ md: 0, sm: 3, xs: 3 }}
                    >
                        <PendingRequest fake={fake} fun={setFake} />
                    </Grid>
                    <Grid item md={9} sm={12} pt={5}>
                        <Box mb={1.5}>
                            {' '}
                            <BreadCrumb breadItems={breadcrumb} />
                        </Box>
                        <Divider
                            sx={{
                                [theme.breakpoints.up('sm')]: {
                                    display: 'none'
                                }
                            }}
                        />
                        <Box
                        //  mx={{ xs: 4, md: 9 }}
                        >
                            {/* <Box
                    display="flex"
                    flexDirection={{ xs: 'row-reverse', md: 'column' }}
                    justifyContent={{ xs: 'space-between' }}
                    alignItems={{ md: 'end' }}
                    // mb={{ xs: 1 }}
                    mt={{ xs: 1.5 }}
                > */}{' '}
                            {/* <Link to="/dashboard">
                        <Button variant="contained" color="secondary">
                            My Courses
                        </Button>
                    </Link> */}
                            {/* <Button
                        variant="text"
                        color="secondary"
                        startIcon={<FilterList className="view-icon" />}
                    >
                        Filter
                    </Button>
                </Box> */}
                            <Box mt={6}>
                                {/* <Stack direction="row" spacing={1}> */}
                                {/* <Chip label="Web Development" /> */}
                                <Link to="/dashboard/courses/create-course">
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                    >
                                        {' '}
                                        Add Course
                                    </Button>
                                </Link>
                                {/* </Stack> */}
                            </Box>
                            {/* <GridView data={data} browseCourses /> */}
                            {/* {data?.length !== 0 && ( */}
                            <Box textAlign="end" mb={3} pr={{ md: 0, sm: 3 }}>
                                <Box
                                    // mt={4}
                                    sx={{
                                        [theme.breakpoints.down('sm')]: {
                                            display: 'none'
                                        },
                                        Zindex: 200,
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    {/* <BasicExampleDataGrid /> */}
                                    {/* <Button onClick={filterAllCategories} color="secondary" variant='outlined'>
                                                All
                                            </Button> */}

                                    <Box mr={2}>
                                        <FormControl>
                                            {/* <IconButton
                                        onClick={handleOpenUserMenu}
                                        sx={{ p: 0 }}
                                    >
                                        <FilterList color="secondary" />{' '}
                                    </IconButton> */}

                                            {/* <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    > */}
                                            <MenuItem
                                                sx={{
                                                    width: 250,
                                                    top: '-32px'
                                                }}
                                            >
                                                <FormControl fullWidth>
                                                    <InputLabel id="category-select-label">
                                                        Major Category
                                                    </InputLabel>
                                                    <Select
                                                        labelId="category-select-label"
                                                        id="demo-simple-select"
                                                        name="majorCategory"
                                                        value={selectedMajor}
                                                        label="Major Category"
                                                        onChange={
                                                            handleCategories
                                                        }
                                                        MenuProps={MenuProps}
                                                    >
                                                        {allCategories &&
                                                            allCategories?.map(
                                                                (
                                                                    obj,
                                                                    index
                                                                ) => (
                                                                    <MenuItem
                                                                        value={
                                                                            obj.categoryName
                                                                        }
                                                                        key={
                                                                            index
                                                                        }
                                                                        subcategories={
                                                                            obj.subCategories
                                                                        }
                                                                    >
                                                                        {
                                                                            obj.categoryName
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            </MenuItem>
                                            {/* </Menu> */}
                                            {/* <Grid item>
                                    <FormControl fullWidth>
                                        <InputLabel id="subCategory">
                                            Sub Category
                                        </InputLabel>
                                        <Select
                                            labelId="subCategory"
                                            id="subCategory"
                                            name="subCategory"
                                            value={selectedSubCat}
                                            label="Sub Category"
                                            onChange={handleCategories}
                                        >
                                            {allSubCategory?.map(
                                                (obj) => (
                                                    <MenuItem
                                                        value={obj.categoryName}
                                                    >
                                                        {obj.categoryName}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid> */}
                                            {/* </Select> */}
                                        </FormControl>
                                    </Box>
                                    <ViewModule
                                        className="view-icon"
                                        sx={
                                            dataView === 'gridView'
                                                ? {
                                                      color: 'secondary.main'
                                                  }
                                                : {
                                                      color: 'primary.light'
                                                  }
                                        }
                                        onClick={() => handleView('gridView')}
                                    />
                                    <ViewList
                                        className="view-icon listIcon"
                                        sx={
                                            dataView === 'listView'
                                                ? {
                                                      color: 'secondary.main'
                                                  }
                                                : {
                                                      color: 'primary.light'
                                                  }
                                        }
                                        onClick={() => handleView('listView')}
                                    />
                                </Box>
                            </Box>
                            {/* )} */}
                            <Box
                                mt={{ xs: 1.5 }}
                                // md={user?.role === 'admin' && 12}
                            >
                                {dataView === 'gridView' ? (
                                    isLoading ? (
                                        <Box textAlign="center">
                                            <CircularProgress />
                                        </Box>
                                    ) : (
                                        <GridView
                                            data={data}
                                            page={page}
                                            setPage={setPage}
                                            count={count}
                                            setCount={setCount}
                                            myCourses={myCourses}
                                            myCourse
                                        />
                                    )
                                ) : (
                                    <ListView
                                        data={data}
                                        page={page}
                                        setPage={setPage}
                                        count={count}
                                        setCount={setCount}
                                        myCourses={myCourses}
                                        myCourse
                                    />
                                )}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default Dashboard;
