import {
    Box,
    TextField,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
    Button,
    Card,
    Chip,
    InputAdornment
} from '@mui/material';
import BreadCrumb from '../../../common/components/Breadcrumb';
import { Field, FormikProvider, useFormik } from 'formik';
import ReactQuill from 'react-quill';
import './index.css';
import { initialValues, validationSchema } from './FormikData';
import {
    createCircular,
    getAllCircular,
    uploadCircularPdf
} from '../../../API/Course';
import { Add } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { format } from 'timeago.js';
import { DataCard } from './DataCard';

export const Circular = () => {
    const breadcrumb = [
        {
            id: '01',
            breadItem: 'Circular'
        }
    ];

    const [forBothCircular, setForBothCircular] = useState([]);
    const [forStudentCircular, setForStudenCircular] = useState([]);
    const [forInstructorCircular, setForInstructorCircular] = useState([]);
    const [allResources, setAllResource] = useState([]);
    const [resourceToBeCreated, setresourceToBeCreated] = useState([]);
    const [resourceToBeDeletedId, setResourceToBeDeletedId] = useState([]);
    const [fake, setFake] = useState(false);

    const uploadResource = (e) => {
        // console.log('all resources', allResources);
        if (allResources?.length >= 3) {
            toast.error('Cannot upload more than 3 resources');
        } else {
            const res = e.target.files[0];
            // console.log('resource in field', e.target.files[0]);
            // console.log('Resource uploading...', res);

            let data = res.type;

            // if (data === 'application/pdf' || 'application/doc' || 'application/') {
            // console.log('all resources', allResources);
            setAllResource([res]);

            setresourceToBeCreated([res]);

            // const existingResource = resourceToBeCreated?.find(
            //     (resource) => resource.name === res.name
            // );

            // if (existingResource) {
            //     toast.error('same file');
            // } else {
            //     setresourceToBeCreated([...resourceToBeCreated, res]);
            // }

            e.target.value = null;
            // } else {
            //     toast.error('please upload pdf');
            // }
        }
    };

    useEffect(() => {
        getAllCirculars();
        setAllResource([]);
        setresourceToBeCreated([]);
    }, [fake]);

    const handleDeleteResource = (id) => {
        // console.log('Delete Id fetched', id);
        allResources.map((resource, index) => {
            // console.log('Compare id', resource._id, '||', id);
            if (resource._id === id) {
                // console.log('Removing index...', index);
                allResources.splice(index, 1);
                setAllResource(allResources);
                setResourceToBeDeletedId([...resourceToBeDeletedId, id]);
            }
        });
    };

    const [showForm, setShowForm] = useState(false);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            // console.log('handlesubmit called');
            const res = await createCircular(values, toast);
            // console.log('res of create circular', res);
            uploadCircularPdf(allResources, res?.data?.data._id);
            setFake(!fake);
            resetForm();
            // setAllResource([]);
            setresourceToBeCreated([]);
            setShowForm(false);
        }
    });

    const getAllCirculars = async () => {
        const res = await getAllCircular();
        // console.log('res', res);
        setForBothCircular(res?.data?.circularForBoth);
        setForInstructorCircular(res?.data?.instructorCirculars);
        setForStudenCircular(res?.data?.studentCirculars);
        setAllResource(res?.data?.resource);
    };

    return (
        <>
            <Box px={14}>
                <Box>
                    <BreadCrumb breadItems={breadcrumb} />
                </Box>
                <Box display="flex" justifyContent="flex-end" my={1}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setShowForm(!showForm)}
                    >
                        Add Circular
                    </Button>
                </Box>
                {showForm && (
                    <Box
                        maxWidth="md"
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        mb={4}
                    >
                        <FormikProvider value={formik}>
                            <form
                                onSubmit={formik.handleSubmit}
                                style={{
                                    display: 'flex',
                                    gap: '24px',
                                    flexDirection: 'column'
                                }}
                            >
                                <TextField
                                    fullWidth
                                    name="agenda"
                                    label="Agenda"
                                    value={formik.values.agenda}
                                    // onChange={formik.handleChange}
                                    onChange={(e) => {
                                        const trimmedValue =
                                            e.target.value.replace(/^\s+/, ''); // Trim spaces before any character
                                        formik.handleChange(e);
                                        formik.setFieldValue(
                                            'agenda',
                                            trimmedValue
                                        );
                                    }}
                                    error={
                                        formik.touched.agenda &&
                                        Boolean(formik.errors.agenda)
                                    }
                                    helperText={
                                        formik.touched.agenda &&
                                        formik.errors.agenda
                                    }
                                />
                                <TextField
                                    label="Description"
                                    fullWidth
                                    multiline
                                    error={
                                        formik.touched.description &&
                                        Boolean(formik.errors.description)
                                    }
                                    helperText={
                                        formik.touched.description &&
                                        formik.errors.description
                                    }
                                    disabled
                                    sx={{
                                        '& .MuiInputBase-root.Mui-disabled': {
                                            '& > fieldset': {
                                                borderColor: 'black'
                                            }
                                        }
                                    }}
                                    InputProps={{
                                        style: {
                                            display: 'block',
                                            paddingBottom: 0
                                        },
                                        startAdornment: (
                                            <Field name="description">
                                                {({ field }) => (
                                                    <ReactQuill
                                                        placeholder="Write Description..."
                                                        theme="snow"
                                                        name="description"
                                                        value={field.value}
                                                        onChange={field.onChange(
                                                            field.name
                                                        )}
                                                        // modules={modules}
                                                    />
                                                )}
                                            </Field>
                                        )
                                    }}
                                />
                                <Box>
                                    <TextField
                                        label="Resources"
                                        disabled
                                        fullWidth
                                        name="pdf"
                                        variant="outlined"
                                        style={{
                                            marginBottom: '20px',
                                            display: 'flex',
                                            flexWrap: 'wrap'
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {/* {console.log(
                                                        'allResources',
                                                        allResources
                                                    )} */}
                                                    {allResources?.map(
                                                        (resource, index) => (
                                                            <Chip
                                                                style={{
                                                                    maxWidth: 100
                                                                }}
                                                                key={index}
                                                                id={index}
                                                                label={
                                                                    resource?.name ||
                                                                    resource?.resourseFile
                                                                        ?.split(
                                                                            'resource-'
                                                                        )
                                                                        .pop()
                                                                }
                                                                onDelete={() =>
                                                                    handleDeleteResource(
                                                                        resource?._id
                                                                    )
                                                                }
                                                                sx={{ ml: 1 }}
                                                            />
                                                        )
                                                    )}
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <div className="upload-btn-wrapper">
                                                        <Button
                                                            variant="contained"
                                                            size="small"
                                                            color="secondary"
                                                            sx={{
                                                                display: 'flex',
                                                                gap: '4px'
                                                            }}
                                                        >
                                                            <Add
                                                                sx={{
                                                                    color: '#fff !important',
                                                                    fontSize:
                                                                        'large'
                                                                }}
                                                            />
                                                            Upload
                                                        </Button>

                                                        <input
                                                            type="file"
                                                            name="resource"
                                                            onChange={
                                                                uploadResource
                                                            }
                                                        />
                                                    </div>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Box>

                                <Box>
                                    <Typography
                                        variant="body1"
                                        sx={{ fontWeight: 'bold' }}
                                    >
                                        Type Of User
                                    </Typography>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="Student"
                                            name="role"
                                            value={formik.values.role}
                                            onChange={formik.handleChange}
                                            sx={{ mt: 2 }}
                                            row
                                            required
                                            error={
                                                formik.touched.role &&
                                                Boolean(formik.errors.role)
                                            }
                                            helperText={
                                                formik.touched.role &&
                                                formik.errors.role
                                            }
                                        >
                                            <FormControlLabel
                                                value="student"
                                                control={<Radio />}
                                                label="Student"
                                            />
                                            <FormControlLabel
                                                value="instructor"
                                                control={<Radio />}
                                                label="Instructor"
                                            />
                                            <FormControlLabel
                                                value="both"
                                                control={<Radio />}
                                                label="Both"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>

                                <Box display="flex" justifyContent="flex-start">
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </form>
                        </FormikProvider>
                    </Box>
                )}
                <Typography variant="h4" mb={4}>
                    Previously added circulars
                </Typography>
                <Box
                    sx={{
                        maxHeight: '60vh',
                        overflow: 'scroll',
                        overflowX: 'hidden'
                    }}
                >
                    <Box width="100%" my={2}>
                        {forBothCircular?.length > 0 && (
                            <Chip label="For All" sx={{ my: 1 }} />
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                                flexDirection: 'column'
                            }}
                        >
                            {forBothCircular?.map((circular, i) => (
                                <DataCard data={circular} id={i} />
                            ))}
                        </Box>
                    </Box>

                    <Box width="100%" my={2}>
                        {forInstructorCircular?.length > 0 && (
                            <Chip label="For Instructors" sx={{ my: 1 }} />
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                                flexDirection: 'column'
                            }}
                        >
                            {forInstructorCircular?.map((circular, i) => (
                                <DataCard data={circular} id={i} />
                            ))}
                        </Box>
                    </Box>

                    <Box width="100%" my={2}>
                        {forStudentCircular?.length > 0 && (
                            <Chip label="For Student" sx={{ my: 1 }} />
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                                flexDirection: 'column'
                            }}
                        >
                            {forStudentCircular?.map((circular, i) => (
                                <DataCard data={circular} id={i} />
                            ))}
                        </Box>
                    </Box>
                </Box>

                <ToastContainer />
            </Box>
        </>
    );
};
