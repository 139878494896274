import Axios from 'axios';
import Apiconfig from '../config/ApiConfig';

let token = localStorage.getItem('token');

export const AddUser = async (values, toast, optional, addSingle) => {
    // console.log('addSingle', addSingle);
    try {
        const response = await Axios.post(Apiconfig.auth.register, values);
        // console.log('Register res', response.values);
        // const sendMessage = await Axios.post(Apiconfig.message.sendMessage);
        // console.log('Message Sent', sendMessage);
        if (response.data.success) {
            toast.success(response.data.message);
            addSingle ? optional('/dashboard/users') : optional();
        } else {
            toast.error('Please! checkin all your fields.');
            toast.error(response.data.message);
        }
    } catch (err) {
        toast.error('Please! checkin all your fields.');
        console.log('Error caught', err);
        toast.error('Error caught', err.message);
    }
};

export const blockUser = async (id) => {
    const res = await Axios.post(`${Apiconfig.users.blockUser}/${id}`, {
        headers: {
            Accept: '/',
            Authorization: `Bearer ${token}`
        }
    });
    return res;
};

export const unBlockUser = async (id) => {
    const res = await Axios.post(`${Apiconfig.users.unBlockUser}/${id}`, {
        headers: {
            Accept: '/',
            Authorization: `Bearer ${token}`
        }
    });
    return res;
};
